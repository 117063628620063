<template>
  <div>
    <topic-component :topic="$t('affiliate.register.topic')"/>

    <b-card>
      <!-- tab form 1   -->
      <overlay-page :visible="is_loading_status">
        <div v-if="active_tab === 1" class="affiliate-form">

          <div class="p-5">
            <div v-if="query_str === 'verified'">
              <b-row align-h="between">
                <b-col>
                  <h5 class="text-primary">{{ $t('affiliate.register.register_form.information') }}</h5>
                </b-col>
                <b-col class="text-right">
                  <h5 class="text-remark-verify-affiliate">
                    {{ $t('affiliate.register.register_form.register_again') }}</h5>
                </b-col>
              </b-row>
              <b-card class="verified-content-box mt-3 p-2">
                <b-row align-h="between" align-v="center">
                  <b-col sm="12" md="12" lg="6" xl="6">
                    <div>
                      <div class="text-primary">
                        {{ $t('affiliate.register.register_form.customer_type') }}: {{
                          verify_info?.customer_type === 'personal' ? $t('account.verify_account.personal') : $t('account.verify_account.juristic')
                        }}
                      </div>
                      <div class="font-size-10">
                        {{ $t('affiliate.register.register_form.cannot_be_edited') }}
                      </div>
                    </div>
                  </b-col>
                  <b-col class="text-right" sm="12" md="12" lg="6" xl="6">
                    <b-button class="btn-verify-success-account" @click="getVerifyInfoAccountPopup()">
                      <div class="d-flex">
                        <img src="@/assets/images/icon/new_releases.svg" alt="ar-on-you">
                        <div class="ml-2 mt-1">{{ $t('account.verify_account.verified') }}</div>
                      </div>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
              <div id="form-affiliate-success-process" class="verify-waiting-box">
                <form-wizard
                    ref="props"
                    hide-buttons
                    :title="null"
                    :subtitle="null"
                    :start-index="2"
                    step-size="xs">
                  <tab-content :title="$t('affiliate.register.register_form.verify_doc')" icon="fa fa-check"/>
                  <tab-content :title="$t('affiliate.register.register_form.check_verify')" icon="fa fa-check"/>
                  <tab-content :title="$t('affiliate.register.register_form.noti_verify')" icon="fa fa-check"/>
                </form-wizard>
              </div>
            </div>
            <div v-if="query_str === 'waiting-approve'">
              <b-row align-h="between">
                <b-col>
                  <h5 class="text-primary">{{ $t('affiliate.register.register_form.information') }}</h5>
                </b-col>
                <b-col class="text-right">
                  <h5 class="text-remark-verify-affiliate">
                    {{ $t('affiliate.register.register_form.register_again') }}</h5>
                </b-col>
              </b-row>
              <b-card class="verified-content-box mt-3 p-2">
                <b-row align-h="between" align-v="center">
                  <b-col sm="12" md="12" lg="6" xl="6">
                    <div>
                      <div class="text-primary">
                        {{ $t('affiliate.register.register_form.customer_type') }} :
                        {{ $t('affiliate.register.register_form.wait_confirm') }}
                      </div>
                      <div class="font-size-10">
                        {{ $t('affiliate.register.register_form.within_24hr') }}
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="12" md="12" lg="3" xl="3">
                    <div class="btn-verify-waiting-account">
                      <div class="text-center">{{ $t('account.verify_account.checking') }}</div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
              <div id="form-affiliate-waiting-process" class="verify-waiting-box">
                <form-wizard
                    ref="props"
                    hide-buttons
                    :title="null"
                    :subtitle="null"
                    :start-index="1"
                    step-size="xs">
                  <template v-slot:step="props">
                    <WizardStep
                        :tab="props.tab"
                        :transition="props.transition"
                        :index="props.index"
                    >
                      <small v-if="props.index === 0" style="font-size: 12px; margin: auto 2px">
                        <i class="fas fa-check text-white mt-1"/>
                      </small>
                    </WizardStep>
                  </template>
                  <tab-content :title="$t('affiliate.register.register_form.verify_doc')"/>
                  <tab-content :title="$t('affiliate.register.register_form.check_verify')"/>
                  <tab-content :title="$t('affiliate.register.register_form.noti_verify')"/>
                </form-wizard>
              </div>
            </div>
            <div v-if="query_str === 'rejected'">
              <b-row align-h="between">
                <b-col>
                  <h5 class="text-primary">{{ $t('affiliate.register.register_form.information') }}</h5>
                </b-col>
                <b-col class="text-right">
                  <h5 class="text-remark-verify-affiliate">
                    {{ $t('affiliate.register.register_form.register_again') }}</h5>
                </b-col>
              </b-row>
              <b-card class="verified-content-box mt-3 p-2">
                <b-row align-h="between" align-v="center">
                  <b-col sm="12" md="12" lg="8" xl="8">
                    <div>
                      <div class="text-primary">
                        {{ $t('affiliate.register.register_form.customer_type') }} :
                        {{ $t('affiliate.register.register_form.wait_confirm') }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <div class="text-danger">
                        {{ $t('affiliate.register.register_form.remark') }} :
                      </div>
                      <div class="text-danger ml-1">
                        {{ reject_remark }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <div>
                        {{ $t('affiliate.register.register_form.please_edit_information') }}
                      </div>
                      <div class="text-primary ml-2">
                        <a href="javascript:void (0)" @click="verifyAccount">
                          {{ $t('affiliate.register.register_form.verify_again') }}</a>
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="12" md="12" lg="3" xl="3">
                    <div class="btn-verify-failed-account">
                      <div class="text-center">{{ $t('account.verify_account.verify_failed') }}</div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
              <div id="form-affiliate-failed-process" class="verify-waiting-box">
                <form-wizard
                    ref="props"
                    hide-buttons
                    :title="null"
                    :subtitle="null"
                    :start-index="2"
                    step-size="xs">
                  <tab-content :title="$t('affiliate.register.register_form.verify_doc')"/>
                  <tab-content :title="$t('affiliate.register.register_form.check_verify')"/>
                  <tab-content :title="$t('affiliate.register.register_form.noti_verify')"/>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
        <!-- tab form 2   -->
        <div v-if="active_tab === 2" class="affiliate-form">
          <div class="p-5">
            <h5 class="text-primary">{{ $t('affiliate.register.bank_detail.info_bank') }}</h5>
            <div>
              {{ $t('affiliate.register.bank_detail.enter_account') }}
            </div>
            <div class="mt-4">
              <b-row>
                <b-col sm="12" md="12" lg="6" xl="6">
                  <input-component :label-input="$t('affiliate.register.bank_detail.name_account')" required
                                   v-model="$v.form.account_name.$model"
                                   :state="validateState('account_name')"
                                   @keypress="isOnlyTHEnglishSpaceNumber"
                                   @keyup="checkFormatFuncOnlyTH_EN_Number_Space"
                                   :require-label="validateState('account_name') !== null && !$v.form.account_name.required ? $t('common.requiredField'): ''"/>
                </b-col>
                <b-col sm="12" md="12" lg="6" xl="6">
                  <input-component :label-input="$t('affiliate.register.bank_detail.account_no')" required
                                   v-model="$v.form.account_no.$model"
                                   :state="validateState('account_no')"
                                   @keypress="checkIsNumber"
                                   @keyup="checkFormatFuncOnlyNumber"
                                   checkFormat="onlyNumber"
                                   max="15"
                                   min="10"
                                   :require-label="validateState('account_no') !== null && !$v.form.account_no.required ? $t('common.requiredField'): ''"/>
                </b-col>
                <b-col sm="12" md="12" lg="6" xl="6">
                  <select-component :label-select="$t('affiliate.register.bank_detail.account')" required
                                    v-model="$v.form.bank.$model"
                                    :state="validateState('bank')"
                                    :options="bank_list"
                                    text-field="text"
                                    :require-label="validateState('bank') !== null && !$v.form.bank.required ? $t('common.requiredField'): ''"/>
                </b-col>
                <b-col sm="12" md="12" lg="12" xl="12" class="mt-3">
                  <upload-file-signature-component :title="$t('affiliate.register.bank_detail.upload_file_bank')"
                                                 required
                                                 :info-content="$t('account.verify_account.upload_id_card_file_info_cintent')"
                                                 :text-upload-content="$t('affiliate.register.bank_detail.upload_file_bank_copy')"
                                                 @input="handleUploadFile"/>
                </b-col>
                <b-col sm="12" md="12" lg="6" xl="6" class="mt-3">
                  <select-component :label-select="$t('affiliate.register.bank_detail.which_channel')" required
                                    v-model="$v.form.which_channel_from.$model"
                                    :state="validateState('which_channel_from')"
                                    :options="which_channel_from_list"
                                    :require-label="validateState('which_channel_from') !== null && !$v.form.which_channel_from.required ? $t('common.requiredField'): ''"/>
                </b-col>
                <b-col sm="12" md="12" lg="6" xl="6" class="mt-3">
                  <input-component :label-input="$t('affiliate.register.bank_detail.other')" 
                                   :required="form.which_channel_from === 'Other' ? true : false"
                                   v-model="$v.form.other_txt.$model"
                                   :state="form.which_channel_from === 'Other' && form.other_txt === '' ? false : true"
                                   :require-label="form.which_channel_from === 'Other' && form.other_txt.trim() === '' ?$t('common.requiredField') : ''"
                                   :disabledType="form.which_channel_from !== 'Other'"/>
                                   
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!-- tab form 3   -->
        <div v-else-if="active_tab === 3">
          <div class="p-5">
            <h5 class="text-primary">{{ $t('affiliate.register.condition.terms_condition') }}</h5>
            <div>
              {{ $t('affiliate.register.condition.please_read_detail') }}
            </div>
          </div>
          <div class="box-condition-form mb-5">
            <div id="inside-box-condition" class="box-condition-form-inside" v-html="condition" @scroll="handleScroll"/>
            <div class="mt-3">
              <b-form-checkbox
                  id="checkbox-terms-condition"
                  name="checkbox-terms-condition"
                  :disabled="last_scroll"
                  v-model="terms.terms_and_condition"
              >
                {{ $t('affiliate.register.condition.i_accept_and_have_read') }}
              </b-form-checkbox>
              <b-form-checkbox
                  id="checkbox-notification"
                  name="checkbox-notification"
                  :disabled="last_scroll"
                  v-model="terms.notification"
              >
                {{ $t('affiliate.register.condition.i_accept_email') }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="affiliate-form" v-if="!is_loading_status">
          <b-row align-h="between" class="p-5">
            <b-col class="text-left">
              <b-button variant="outline-primary" class="w-25" @click="back()">
                {{ $t('common.previous') }}
              </b-button>
            </b-col>
            <b-col class="text-right">
              <b-button v-if="active_tab === 1" variant="primary" class="w-25" @click="next(active_tab)"
                        :disabled="query_str !== 'verified'">
                {{ $t('affiliate.register.register_btn') }}
              </b-button>
              <b-button v-if="active_tab === 2" variant="primary" class="w-25" @click="next(active_tab)"
                        :disabled="query_str !== 'verified' || invalidInput || (form.which_channel_from === 'Other' && form.other_txt.trim() === '')" >
                {{ $t('common.next') }}
              </b-button>
              <b-button v-if="active_tab === 3" variant="primary" class="w-25" @click="createRegisterAffiliate"
                        :disabled="query_str !== 'verified' || !terms.terms_and_condition || !terms.notification">
                {{ $t('button.confirm') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </overlay-page>
    </b-card>
    <div>
      <modal-affiliate-success @update="onUpdateSuccess" :link_url="affiliate_url"/>
    </div>
    <div v-if="verify_info">
      <VerifyModal @update="updateVerifyInfoSuccess" :customer-profile="userInfo"/>
      <verify-info :user-info="userInfo" :data="verify_info" @update-file-success="updateVerifyInfoSuccess"/>
    </div>
  </div>
</template>
<script>
import TopicComponent from "@/components/common/topicComponent.vue";
import InputComponent from "@/components/common/inputComponent.vue";
import UploadFileSignatureComponent from "@/components/common/uploadFileSignatureComponent.vue";
import ModalAffiliateSuccess from "@/views/affiliate/register/modalAffiliateSuccess.vue";
import {FormWizard, TabContent, WizardStep} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import affiliateApi from "@/repository/affiliateApi";
import {validationMixin} from "vuelidate";
import {required, maxLength, minLength} from "vuelidate/lib/validators";
import functions from "@/common/functions";
import SelectComponent from "@/components/common/selectComponent.vue";
import SelectAllComponent from "@/components/common/selectAllComponent.vue";
import profileApi from "@/repository/profileApi";
import masterData from "@/common/masterData.json";
import VerifyInfo from "@/views/account/verify/verifyInfo.vue";
import alert from "@/common/alert";
import condition from "@/common/conditionAffiiliate.json"
import VerifyModal from "@/views/account/verify/index.vue";
import functionsCommon from "@/common/functions";
import OverlayPage from "@/components/common/overlayPage.vue";

export default {
  name: "registerForm",
  components: {
    OverlayPage,
    VerifyModal,
    VerifyInfo,
    SelectAllComponent,
    SelectComponent,
    ModalAffiliateSuccess,
    UploadFileSignatureComponent,
    InputComponent,
    TopicComponent,
    FormWizard,
    TabContent,
    WizardStep,
  },
  mixins: [validationMixin],
  data() {
    return {
      active_tab: 1,
      custom_type: 'juristic',
      condition: condition.condition,
      query_str: '',
      last_scroll: true,
      form: {
        account_name: "",
        account_no: "",
        bank: null,
        book_bank: "",
        which_channel_from: null,
        other_txt: ""
      },
      terms: {
        terms_and_condition: false,
        notification: false
      },
      affiliate_url: null,
      bank_list: [],
      which_channel_from_list: masterData.whichChannelFromList,
      verify_remark_list: masterData.verifyRemarkList,
      verify_info: {},
      reject_remark: "",
      verifyRemarkList: masterData.verifyRemarkList,
      is_loading_status: false
    }
  },
  computed: {
    active() {
      return {
        tab: this.active_tab,
        total: 2
      }
    },
    invalidInput() {
      return this.$v.$invalid || this.form.book_bank === ''
    },
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    }
  },
  validations: {
    form: {
      account_name: {
        required,
      },
      account_no: {
        required: required,
        maxLength: maxLength(15),
        minLength: minLength(10)
      },
      bank: {
        required,
      },
      which_channel_from: {
        required,
      },  
      other_txt : {
      }
    },
  },
  mounted() {
    this.query_str = this.$route.query.is_verify
    if (this.query_str) {
      this.getBankAllList()
      this.verifyInfoAccount()
    }
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username]
      return $dirty ? !$error : null
    },
    createRegisterAffiliate() {
      const data = {
        bank_info: {
          account_name: this.form.account_name,
          account_no: this.form.account_no,
          bank: this.form.bank,
          book_bank: this.form.book_ban
        },
        which_channel_from: this.form.which_channel_from,
        which_channel_from_detail: " "
      }
      if (this.form.which_channel_from === 'Other') {
        data.which_channel_from_detail = this.form.other_txt
      }
      affiliateApi.registerAffiliate(data).then(resp => {
        if (!!resp) {
          this.affiliate_url = resp.affiliate_link
          this.$bvModal.show('modal-register-affiliate-success')
        }
      }).catch(err => {
        alert.addNewFailed(this, err.response?.data?.message)
      })
    },
    next(index) {
      this.active_tab = index + 1
    },
    back() {
      window.history.back()
    },
    handleScroll: function (el) {
      if ((el.srcElement.offsetHeight + el.srcElement.scrollTop) >= el.srcElement.scrollHeight) {
        this.last_scroll = false;
      }
    },
    onUpdateSuccess() {
      setTimeout(() => {
        sessionStorage.setItem('reload_page', 'true')
        this.$router.push('/Affiliate/Affiliate-Dashboards')
      }, 600)

    },
    checkIsNumber(e) {
      return functions.checkNumber(e)
    },
    checkFormatFuncOnlyNumber() {
      const regex = /[^0-9]/g
      const isNumber = this.form.account_no.replace(regex, '')
      this.form.account_no = isNumber
    },
    checkFormatFuncOnlyTH_EN_Number_Space() {
      const regex = /[^a-zA-Z0-9ก-๙ ]/g
      const isNumber = this.form.account_name.replace(regex, '')
      this.form.account_name = isNumber
    },
    isOnlyTHEnglishSpaceNumber(e) {
      return functions.isOnlyTHEnglishSpaceNumber(e)
    },
    handleUploadFile(e) {
      if (e === null) {
        this.form.book_bank = ''
      } else {
        this.form.book_bank = e
      }
    },
    verifyInfoAccount() {
      this.is_loading_status = true
      profileApi.getVerifyInfo().then(resp => {
        if (!!resp) {
          this.verify_info = resp
          let array = []
          _.forEach(resp?.remark, item => {
            array.push(this.filterValue(this.verify_remark_list, item))
            this.reject_remark = _.join(array, ' , ')
          })
        }
      }).catch(err => {
        throw err
      })
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    getVerifyInfoAccountPopup() {
      this.$bvModal.show('modal-verify-info')
    },
    getBankAllList() {
      affiliateApi.getMasterBankAccountList().then(resp => {
        this.bank_list = _.map(resp.data, item => {
          return {
            text: item.name,
            value: item.value
          }
        })

      }).catch(err => {
        throw err
      })
    },
    updateVerifyInfoSuccess() {
      this.verifyInfoAccount()
      this.is_loading_status = false
    },
    verifyAccount() {
      this.$bvModal.show('modal-verify-account')
    },
  },
  destroyed() {
    this.$bvModal.hide('modal-verify-info')
    this.$bvModal.hide('modal-verify-account')
  },
  watch: {
    'verify_info.status'(status, oldStatus) {
      if (status !== oldStatus) {
        this.$router.push(`/Affiliate-Register/Register-Form?is_verify=${status}`).then(() => {
          window.location.reload()
        }).catch(() => {
        }).finally(() => {
          this.is_loading_status = false
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.affiliate-form {
  padding-left: 5rem;
  padding-right: 5rem;
}

.box-condition-form {
  background: rgba(241, 249, 253, 1);
  padding: 1rem;
}

.verified-content-box {
  padding: 1rem;
  border-radius: 10px;
  background: #FFF;
  box-shadow: -1px 1px 9.3px 0px rgba(0, 0, 0, 0.20);
}

.btn-verify-success-account {
  background: linear-gradient(90deg, #02A4A4 0%, #00E3BA 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  padding: .6rem 2rem .6rem 2rem;
  vertical-align: middle;
}

.btn-verify-success-account:hover, .btn-verify-success-account:focus {
  background: linear-gradient(90deg, #02A4A4 0%, #00E3BA 100%) !important;
}

.btn-verify-waiting-account {
  padding: .6rem 2rem .6rem 2rem;
  border-radius: 2rem;
  background: rgba(255, 247, 175, 1);
  color: rgba(227, 177, 0, 1);
  vertical-align: middle;
}

.btn-verify-failed-account {
  padding: .6rem 2rem .6rem 2rem;
  border-radius: 2rem;
  background: #FFDADA;
  color: #D60000;
  vertical-align: middle;
}

.box-condition-form-inside {
  border-radius: 10px;
  border: 1px solid #586A8F;
  background: #FFFFFF;
  padding: 1rem;
  overflow-y: scroll;
  min-height: 466px;
  max-height: 466px;
}

#inside-box-condition::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  background-color: #F5F5F5 !important;
}

.verify-waiting-box {
  border-top: 0;
  border-right: 1px solid rgba(233, 233, 233, 1);
  border-bottom: 1px solid rgba(233, 233, 233, 1);
  border-left: 1px solid rgba(233, 233, 233, 1);
  padding: 1rem;
  border-radius: 0 0 10px 10px;
  margin-top: -1rem;
  z-index: 0;
}

#form-affiliate-success-process::v-deep {
  .vue-form-wizard {
    text-align: center !important;
    font-style: normal !important;
    padding-bottom: 10px !important;
  }

  .vue-form-wizard .wizard-header {
    padding: 0 !important;
  }

  .vue-form-wizard .wizard-tab-content {
    min-height: 0 !important;
    padding: 0;
  }

  .vue-form-wizard .wizard-nav-pills > li > a {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 auto !important;
    color: #0003 !important;
    position: relative !important;
    top: 3px !important;
    font-style: normal !important;
  }

  .vue-form-wizard .wizard-icon-circle.checked {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: var(--primary-color) !important;
    border: 3px solid #FFFFFF !important;
    box-shadow: 0 0 0 3px var(--primary-color) !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: var(--primary-color) !important;
    border: 3px solid #FFFFFF !important;
    box-shadow: 0 0 0 3px var(--primary-color) !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle .wizard-icon {
    font-style: normal !important;
  }

  .wizard-icon-container {
    background-color: transparent !important;
  }

  .wizard-progress-bar {
    background-color: var(--primary-color) !important;
  }

  .wizard-progress-with-circle {
    background-color: var(--primary-color) !important;
    width: 65% !important;
  }

  .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
    position: relative !important;
    top: 28px !important;
    height: 4px !important;
    display: inline-block !important;
  }

  .stepTitle {
    color: #2F2E2E !important;
    margin-top: .8rem;
  }

  @media (max-width: 768px) {
    .vue-form-wizard .wizard-tab-content {
      min-height: 100px;
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-header {
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-icon-circle {
      width: 1.6em !important;
      height: 1.6em !important;
    }

    .vue-form-wizard .wizard-icon-circle.checked {
      width: 1.6em !important;
      height: 1.6em !important;
    }
  }


}

#form-affiliate-failed-process::v-deep {
  .vue-form-wizard {
    text-align: center !important;
    font-style: normal !important;
    padding-bottom: 10px !important;
  }

  .vue-form-wizard .wizard-header {
    padding: 0 !important;
  }

  .vue-form-wizard .wizard-tab-content {
    min-height: 0 !important;
    padding: 0;
  }

  .vue-form-wizard .wizard-nav-pills > li > a {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 auto !important;
    color: #0003 !important;
    position: relative !important;
    top: 3px !important;
    font-style: normal !important;
  }

  .vue-form-wizard .wizard-icon-circle.checked {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: rgba(159, 159, 159, 1) !important;
    border: 1px solid rgba(159, 159, 159, 1) !important;
    box-shadow: 0 0 0 2px rgba(159, 159, 159, 1) !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: rgba(159, 159, 159, 1) !important;
    border: 1px solid rgba(159, 159, 159, 1) !important;
    box-shadow: 0 0 0 2px rgba(159, 159, 159, 1) !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle .wizard-icon {
    font-style: normal !important;
  }

  .wizard-icon-container {
    background-color: transparent !important;
  }

  .wizard-progress-bar {
    background-color: #FFFFFF !important;
  }

  .wizard-progress-with-circle {
    background-color: #FFFFFF !important;
    border-top: 3px dashed rgba(159, 159, 159, 1) !important;
    width: 65% !important;
  }

  .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
    position: relative !important;
    top: 28px !important;
    height: 4px !important;
    display: inline-block !important;
  }

  .stepTitle {
    color: #2F2E2E !important;
    margin-top: .8rem;
  }

  @media (max-width: 768px) {
    .vue-form-wizard .wizard-tab-content {
      min-height: 100px;
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-header {
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-icon-circle {
      width: 1.6em !important;
      height: 1.6em !important;
    }

    .vue-form-wizard .wizard-icon-circle.checked {
      width: 1.6em !important;
      height: 1.6em !important;
    }
  }
}

#form-affiliate-waiting-process::v-deep {
  .vue-form-wizard {
    text-align: center !important;
    font-style: normal !important;
    padding-bottom: 10px !important;
  }

  .vue-form-wizard .wizard-header {
    padding: 0 !important;
  }

  .vue-form-wizard .wizard-tab-content {
    min-height: 0 !important;
    padding: 0;
  }

  .vue-form-wizard .wizard-nav-pills > li > a {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 auto !important;
    color: #0003 !important;
    position: relative !important;
    top: 3px !important;
    font-style: normal !important;
  }

  .vue-form-wizard .wizard-icon-circle.checked {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: var(--primary-color) !important;
    border: 3px solid #FFFFFF !important;
    box-shadow: 0 0 0 3px var(--primary-color) !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: #AAAAAA !important;
    border: 0 solid #fff !important;
    box-shadow: 0 0 0 3px #AAAAAA !important;
    color: #FFFFFF !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle .wizard-icon {
    font-style: normal !important;
  }

  .wizard-icon-container {
    background-color: transparent !important;
  }

  .wizard-progress-bar {
    background-color: var(--primary-color) !important;
  }

  .wizard-progress-with-circle {
    background-color: #AAAAAA !important;
    width: 65% !important;
  }

  .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
    position: relative !important;
    top: 28px !important;
    height: 4px !important;
    display: inline-block !important;
  }

  .stepTitle {
    color: #2F2E2E !important;
    margin-top: .8rem;
  }

  @media (max-width: 768px) {
    .vue-form-wizard .wizard-tab-content {
      min-height: 100px;
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-header {
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-icon-circle {
      width: 1.6em !important;
      height: 1.6em !important;
    }

    .vue-form-wizard .wizard-icon-circle.checked {
      width: 1.6em !important;
      height: 1.6em !important;
    }
  }
}

.text-remark-verify-affiliate {
  color: var(--danger);
  text-decoration: underline;
}
</style>