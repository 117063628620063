<template>
  <b-modal id="modal-condition-register" size="lg" hide-footer centered no-close-on-esc no-close-on-backdrop>
    <template #modal-header="{ close }">
      <div></div>
      <i class="fas fa-times hover-icon action-button" @click="close"/>
    </template>
    <div class="p-2 ml-4 mr-4 mb-4">
      <div class="text-bold mb-3">
        {{ $t('affiliate.register.condition_list.condition') }}
      </div>
      <ul>
        <li v-for="(item, index) in condition_list" :key="index">{{ $t(item.item) }}</li>
      </ul>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "conditionRegister",
  data() {
    return {
      condition_list: [
        {
          id: 1,
          item: "affiliate.register.condition_list.withdraw_money"
        },
        {
          id: 2,
          item: "affiliate.register.condition_list.summary_of_balance"
        },
        {
          id: 3,
          item: "affiliate.register.condition_list.only_transfer_money"
        },
        {
          id: 4,
          item: "affiliate.register.condition_list.condition_for_withholding"
        },
        {
          id: 5,
          item: "affiliate.register.condition_list.condition_for_calculating"
        },
        {
          id: 6,
          item: "affiliate.register.condition_list.do_not_place_order"
        },
        {
          id: 7,
          item: "affiliate.register.condition_list.account_more_than_1_year"
        },
        {
          id: 8,
          item: "affiliate.register.condition_list.company_decision"
        },

      ]
    }
  }
}
</script>

<style>
</style>