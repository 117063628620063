<template>
  <div>
    <b-modal id="modal-register-affiliate-success" size="lg" hide-footer centered no-close-on-esc no-close-on-backdrop>
      <template #modal-header="{ close }">
        <div></div>
        <i class="fas fa-times hover-icon action-button" @click="closePupUp()"/>
      </template>
      <div class="text-center p-3">
        <div>
          <img src="@/assets/images/icon/checkmark-1.svg" alt="check-mark">
        </div>
        <div class="mt-3 mb-5">
          <h2>{{$t('affiliate.register.success.title')}}</h2>
        </div>
        <div class="mt-3">
          <b-row align-h="center">
            <b-col cols="3">
              <div class="mt-2">
                {{$t('affiliate.register.success.affiliate_link')}}
              </div>
            </b-col>
            <b-col class="text-center">
              <input-component input-id="url-link-input" :value="link_url"
                               min-height="2em" disabled-type/>
            </b-col>
            <b-col cols="2" class="text-left">
              <b-button class="ml-2" variant="primary" @click="copyText">
                <i class="fas fa-copy"/>
                {{$t('affiliate.register.success.copy')}}
              </b-button>
            </b-col>
          </b-row>
          <hr/>
          <div class="justify-content-center mb-3">
            <div>{{$t('affiliate.register.success.thank_you')}}</div>
            <div>{{$t('affiliate.register.success.copy_link')}}</div>
            <div>{{$t('affiliate.register.success.commission')}}</div>
            <div>{{$t('affiliate.register.success.commission_detail')}}</div>
          </div>

          <div class="text-center mb-4">
            <b-button variant="primary" class="w-25" @click="onCreateAffiliateSuccess">
              {{ $t('button.confirm') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import InputComponent from "@/components/common/inputComponent.vue";

export default {
  name: "modalAffiliateSuccess",
  components: {InputComponent},
  props: {
    link_url: {
      type: String,
      default: ""
    }
  },
  methods: {
    onCreateAffiliateSuccess() {
      this.$emit('update', true)
    },
    copyText() {
      const textInput = document.getElementById('url-link-input').value;
      navigator.clipboard.writeText(textInput).then(() => {
        this.$bvToast.toast(`Copied successfully`, {
          title: 'Copy',
          autoHideDelay: 5000,
        })
      }).catch(err => {
        console.error('Error copying text: ', err);
      });
    },
    closePupUp () {
      this.$emit('update', true)
    }
  }
}
</script>

<style scoped>
#url-link-input::v-deep .form-control:disabled {
  background: #0bb7af !important;
  color: #5C698C!important;
  font-style: italic!important;
}
</style>