import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = '/affiliate'
const withdrawalSubPath = '/withdraw'
export default {
  withdrawList(search) {
    if (search.$skip) {
      search.$skip = (search.$skip - 1) * search.$limit
    }
    const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
    const str = queryString.toString()
    return service.apiAuth.get(`${resourcePath}${withdrawalSubPath}/list?${str}`)
      .then(resp => {
        return resp.data
      }).catch(err => {
        throw err
      })
  },
  exportWithdrawList(search) {
    const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
    const str = queryString.toString()
    return service.apiAuth.post(`${resourcePath}${withdrawalSubPath}/export?${str}`)
      .then(resp => {
        return resp.data
      }).catch(err => {
        throw err
      })
  },
  cancelWithdrawRequest(id) {
    return service.apiAuth.patch(`${resourcePath}${withdrawalSubPath}/cancel/${id}`)
      .then(resp => {
        return resp.data
      }).catch(err => {
        throw err
      })
  },
  getSummary(customer_id) {
    return service.apiAuth.get(`${resourcePath}${withdrawalSubPath}/summary?customer_id=${customer_id}`)
      .then(resp => {
        return resp.data
      }).catch(err => {
        throw err
      })
  },
  createRequest(data) {
    // const raw = JSON.stringify(functionsCommon.removeEmptyKey(data))
    return service.apiAuth.post(`${resourcePath}${withdrawalSubPath}/create`, data)
      .then(resp => {
        return resp.data
      }).catch(err => {
        if (err.response.data) {
          throw err.response.data
        }
        throw err.response
      })
  },
  registerAffiliate (data) {
    return service.apiAuth.post(`${resourcePath}/register`, data).then(resp => {
      return resp.data
    }).catch(err => {
      throw err
    })
  },
  getAffiliateDashboardList (data) {
    if (data.$skip) {
      data.$skip = (data.$skip - 1) * data.$limit
    }
    const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(data))
    const str = queryString.toString()
    return service.apiAuth.get(`${resourcePath}/dashboard/list?${str}`).then(resp => {
      return resp?.data
    }).catch(err => {
      throw err
    })
  },
  getSummaryBoxDashboard (data) {
    const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(data))
    const str = queryString.toString()

    return service.apiAuth.get(`${resourcePath}/dashboard/summary?${str}`).then(resp => {
      return resp?.data
    }).catch(err => {
      throw err
    })
  },
  getAffiliateDashboardDetail (data) {
    const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(data))
    const str = queryString.toString()
    return service.apiAuth.get(`${resourcePath}/dashboard/detail-list?${str}`).then(resp => {
      return resp?.data
    }).catch(err => {
      throw err
    })
  },
  getMemberDetailAffiliate () {
    return service.apiAuth.get(`${resourcePath}/members/get`).then(resp => {
      return resp?.data
    }).catch(err => {
      throw err
    })
  },
  reviewRequest(data) {
    const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(data))
    const str = queryString.toString()
    return service.apiAuth.get(`${resourcePath}${withdrawalSubPath}/detail?${str}`).then(resp => {
      return resp?.data
    }).catch(err => {
      if (err.response.data) {
        throw err.response.data
      }
      throw err.response
    })
  },
  getRequestDetails(id) {
    return service.apiAuth.get(`${resourcePath}${withdrawalSubPath}/get/${id}`).then(resp => {
      return resp?.data
    }).catch(err => {
      if (err.response.data) {
        throw err.response.data
      }
      throw err.response
    })
  },
  updateMemberInfoDetail (payload) {
    return service.apiAuth.patch(`${resourcePath}/members/update-bank-info`, payload).then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
  },
  updateNotification (payload) {
    return service.apiAuth.patch(`${resourcePath}/members/update-notifications`, payload).then(resp => {
      return resp
    }).catch(err => {
      throw err
    })
  },
  getMasterBankAccountList () {
    return service.apiAuth.get(`/master-bank/list`).then(resp => {
      return resp?.data
    }).catch(err => {
      throw err
    })
  }
}