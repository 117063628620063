<template>
  <div v-if="checkPermission('register', 'action', 'affiliate')">
    <b-card>
      <div class="m-3">
        <div class="box-banner">
          <img v-if="$i18n.locale == 'th'" src="@/assets/images/affiliate/affiliate_banner.png"/>
          <img v-else src="@/assets/images/affiliate/affiliate_banner_en.png"/>
        </div>
        <div class="mt-5">
          <b-row>
            <b-col sm="12" md="12" lg="5" xl="5">
              <div class="commission-banner">
                <img v-if="$i18n.locale == 'th'" src="@/assets/images/upgrade/commission15per_thai.png" alt="commission_banner">
                <img v-else src="@/assets/images/upgrade/commission15per_eng.png" alt="commission_banner">
              </div>

            </b-col>
            <b-col sm="12" md="12" lg="7" xl="7">
              <div class="box-content-affiliate">
                <div>
                  <label class="text-secondary font-size-24 text-bold mr-2" style="margin-bottom: 0;">
                    {{ $t('affiliate.register.banner.program') }}
                  </label>
                  {{ $t('affiliate.register.banner.content') }}
                </div>
                <div class="mt-3">
                  <div class="font-size-32"> {{ $t('affiliate.register.banner.every_sale') }}</div>
                  <div class="font-size-32"> {{ $t('affiliate.register.banner.every_sale_commission') }}</div>
                  <div class="mt-4">
                    <a class="text-secondary read-condition"
                       @click="conditionMore()">{{ $t('affiliate.register.banner.additional_condition') }} <i
                        class="fas fa-chevron-right font-size-18 ml-2"></i></a>
                  </div>
                </div>
                <div class="mt-5">
                  <b-button variant="primary" class="btn-regis-affiliate"
                            :disabled="!checkPermission('register', 'action', 'affiliate')"
                            @click="checkVerified()">
                    {{ $t('affiliate.register.banner.register_affiliate') }}
                    <i class="fas fa-arrow-right ml-2"></i>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <div v-if="userInfo">
      <VerifyModal @update="updateVerifyInfoSuccess" :customer-profile="userInfo"/>
    </div>
    <condition-register/>
  </div>
</template>

<script>
import VerifyModal from "@/views/account/verify/index.vue";
import ConditionRegister from "@/views/affiliate/register/conditionRegister.vue";
import functions from "@/common/functions";
import emailMaster from "@/common/emailMasterTest"

export default {
  name: "registerAffiliate",
  components: {ConditionRegister, VerifyModal},
  data() {
    return {}
  },
  computed: {
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    },
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
    checkEmailMasterTest() {
      const email = this.$store.getters["profile/getProfile"]?.email
      const email_master = emailMaster?.email
      return _.includes(email_master, email);
    }
  },
  methods: {
    checkVerified() {
      if (this.userInfo?.identity_verify_status === 'verified' || this.userInfo?.identity_verify_status === 'waiting-approve' || this.userInfo?.identity_verify_status === 'rejected') {
        this.$router.push(`/Affiliate-Register/Register-Form?is_verify=${this.userInfo?.identity_verify_status}`).catch(() => {
        })
      } else {
        this.$swal.fire({
          imageUrl: require('@/assets/images/icon/verify.svg'),
          title: this.$t('common.has_not_been_auth'),
          text: this.$t('common.please_verify_your_credential_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_verify_your_credential_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width: '40em',
          padding: '1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$bvModal.show('modal-verify-account')
          }
        })
      }
    },
    updateVerifyInfoSuccess() {
      this.$router.push(`/Affiliate-Register/Register-Form?is_verify=${this.userInfo?.identity_verify_status}`).catch(() => {
      })
    },
    conditionMore() {
      this.$bvModal.show('modal-condition-register')
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
  }
}
</script>

<style scoped>
.box-banner {
  height: fit-content;
  border-radius: 12px!important;
  background: rgba(241, 249, 253, 1);
  position: relative;
}

.box-banner img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 12px!important;
  @media (max-width: 1200px) {
    object-fit: contain;
  }
}

.box-content-affiliate {
  padding: 4rem;
  font-size: 20px;
}

.box-content-condition {
  height: fit-content;
  border-radius: 12px;
  background: rgba(241, 249, 253, 1);
  padding: 2rem;
  font-size: 14px;
}

.btn-regis-affiliate {
  width: 12rem;
  padding: .7rem;
  font-size: 18px;
}

.read-condition {
  cursor: pointer;
}

.commission-banner {
  text-align: center;
  vertical-align: middle;
  justify-items: center;
  position: relative;
  padding: 4rem 1rem 1rem;
  > img {
    width: 100%;
  }
}
</style>