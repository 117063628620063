<template>
  <div>
    <register-affiliate/>
  </div>
</template>

<script>

import RegisterAffiliate from "@/views/affiliate/register/index.vue";
import RegisterForm from "@/views/affiliate/register/registerForm.vue";

export default {
  name: "index",
  components: {RegisterForm, RegisterAffiliate},
}
</script>

<style scoped>
</style>